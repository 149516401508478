<form class="flex flex-col max-w-2xl mx-auto" [formGroup]="purposeForm">
	<div class="flex flex-col justify-between flex-1 bg-white sm:rounded-box">
		<div>
			<div class="flex flex-col">
				<div class="flex w-fit">
					<app-button *ngIf="showMoreOptions" class="mb-1" [type]="'small'" [contentPosition]="'left'" [iconId]="'chevron-down'" (action)="handleShowMore()">
						{{ 'application.aml.loan-purpose.show-more' | translate }}
					</app-button>
				</div>

				<div *ngFor="let purposeOption of (purposes$ | async); let i = index" class="flex flex-col">
					<app-list-item
						class="my-1"
						*ngIf="!purposeCtrl?.value || (purposeOption === purposeCtrl?.value)"
						[listItem]="purposeOption"
						[imageSourceUrl]="imageSourceUrl"
						[isSelected]="purposeOption === purposeCtrl?.value"
						[itemStyle]="itemStyle"
						[showDescription]="true"
						(optionClick)="handleOptionClick($event)"
						[attr.data-testid]="'purpose-list-item-' + i"></app-list-item>
				</div>
			</div>
			<ng-container formArrayName="answers" *ngFor="let answer of answersCtrl?.controls; let i = index">
				<div *ngIf="purposeCtrl?.value" class="mt-4">
					<app-input
						[label]="('purpose.question.' + purposeCtrl.value.questions[i].code) | translate"
						[placeholder]="('purpose.' + purposeCtrl.value.questions[i].code) + '.placeholder' | translate"
						[type]="purposeCtrl.value.questions[i].code === 'credit-settlement-how-much' ? 'number' : 'text'"
						[suffix]="purposeCtrl.value.questions[i].code === 'credit-settlement-how-much' ? currencySuffix : ''"
						[name]="i"
						[formControlName]="i"></app-input>
				</div>
			</ng-container>
		</div>
		<div *ngIf="purposeCtrl?.value && showSubmitButton" class="mt-12 -mb-4">
			<app-button class="self-center w-full max-w-lg mt-2" (click)="buttonClicked()" [loading]="loading">
				{{ 'onboarding.partner-portal.create-session.send-application' | translate }}
			</app-button>
			<div class="mt-4">
				<app-notification *ngIf="hasErrors" type="error">
					{{ 'onboarding.partner-portal.prefill-session.has-errors' | translate }}
				</app-notification>
			</div>
		</div>
	</div>
</form>
