import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WithdrawalRequestDto } from 'app/api';
import { MEDIUM_BREAKPOINT } from 'app/app.contants';

@Component({
	selector: 'app-rejected-status-card',
	templateUrl: './rejected-status-card.component.html',
	styleUrls: ['./rejected-status-card.component.scss'],
})
export class RejectedStatusCardComponent implements OnInit {
	@Input() rejectedRequest: WithdrawalRequestDto;
	@Input() currency: string;
	@Input() widthClass = 'max-w-5xl';
	@Output() close = new EventEmitter<void>();
	collapsed: boolean;

	constructor() {
		this.collapsed = window.innerWidth < MEDIUM_BREAKPOINT ? true : false;
	}

	get hasPreviousLoan(): boolean {
		return this.rejectedRequest && this.rejectedRequest.current_principal_due.amount > 0;
	}

	ngOnInit(): void {}
}
