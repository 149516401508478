<div class="w-full" [class.pb-8]="!hasErrors()">
	<div class="pb-1">
		<label class="{{labelSize}} text-black leading-6 pointer-events-none" for="{{label}}">{{ label }}</label>
	</div>
	<div class="relative h-full custom-select">
		<select
			[ngModel]="value"
			(ngModelChange)="onChange($event)"
			(blur)="onTouched()"
			[required]="required"
			[ngModelOptions]="{standalone: true}"
			class="block w-full h-12 px-4 text-base leading-normal text-black bg-white border rounded outline-none appearance-none cursor-pointer focus:ring-0 placeholder-medium-gray test-black focus:test-white"
			[ngClass]="{
				'border-medium-grey': !hasErrors(),
				'border-system-red' : hasErrors(),
				'focus:border-black hover:border-black' : !hasErrors(),
				'focus:border-system-red' : hasErrors() }">
			<option value="" selected hidden>{{ placeholder  || 'select.placeholder' | translate}}</option>
			<option [ngValue]="option" *ngFor="let option of options">{{ (option.text || option.label) | translate }}</option>
		</select>
		<div class="absolute bottom-0 flex items-center h-full px-1 right-12">
			<app-svg-icon
				*ngIf="hasErrors() && control.errors?.required"
				class="text-system-red"
				[iconName]="'alert.svg'"
				[iconId]="'alert'"
				[width]="'20'"
				[height]="'20'"></app-svg-icon>
		</div>
	</div>
	<div *ngIf="hasErrors() && control.errors?.required" class="flex justify-end px-2 pt-1 text-sm text-system-red">
		{{ 'control.error.required' | translate }}
	</div>
	<div *ngIf="optional" class="flex justify-end px-2 pt-1 text-sm text-dark-grey">
		{{ 'input-component.optional-text' | translate }}
	</div>
</div>
