import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-awaiting-payout-status-card',
	templateUrl: './awaiting-payout-status-card.component.html',
	styleUrls: ['./awaiting-payout-status-card.component.scss'],
})
export class AwaitingPayoutStatusCardComponent implements OnInit {
	@Input() amount: string;
	@Input() widthClass = 'max-w-5xl';
	constructor() {}

	ngOnInit(): void {}
}
