export const TestBootstrapper = {
	appConfig: {
		apiBaseUrl: 'https://api.froda-dev.se',
		authBaseUrl: 'https://auth.froda-dev.se',
		clientBaseUrl: 'https://home.froda-dev.se',
		clientBaseUrlAlt: 'https://my.froda-dev.se',
		contractAppUrl: 'https://contract.froda-dev.se',
		scrapingBaseUrl: 'https://scraping-api.froda-dev.se',
		identityBaseUrl: 'https://identity.froda-dev.se',
		unleashBaseUrl: 'https://eu.app.unleash-hosted.com/euee0006/api/frontend',
		unleashClientKey: '[]:development.b1dec87ad9f21473370fd9e9455296bc30807e9d56ee7abf2a70182d',
		smartlookClientKey: '7e1c2401d488485e870d669b56401076bf1a232c',
		checkoutPublicKey: 'pk_sbox_xwwru546fr6xznu7wqq4f6c2iim',
		mockDelay: 0,
		redirectUrl: 'home.froda-dev.se/',
		identityClientId: 'f0c2822a-3072-4db5-bcfd-c80133c213b8',
	},
	providers: [],
};
