import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { OrganizationService } from '@services';
import { ContractsHttpService, WithdrawalRequestDto } from 'app/api';
import { MEDIUM_BREAKPOINT } from 'app/app.contants';
import { allSignatoriesSigned, contractsCreated, requestApproved } from 'app/services/withdrawal-request-functions';
import { AuthService } from 'app/shared/auth';
import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import { Observable, Subject, defer, iif, of, throwError, timer } from 'rxjs';
import {
	catchError,
	exhaustMap,
	filter,
	map,
	publishReplay,
	refCount,
	switchMap,
	takeUntil,
	takeWhile,
	tap,
} from 'rxjs/operators';

dayjs.extend(relativeTime);

const CONTRACT_SIGNING_TIMEOUT = 60000;
const CONTRACT_SIGNING_URL_POLLING_INTERVAL = 2000;
const CONTRACT_ALREDY_SIGNED_OR_RECALLED_STATUS = 422;
const CONTRACT_NOT_READY_STATUS = 404;
const CONTRACT_SIGNED = 'completed';
const CONTRACT_RECALLED = 'recalled';

@Component({
	selector: 'app-pending-approved-status-card',
	templateUrl: './pending-approved-status-card.component.html',
	styleUrls: ['./pending-approved-status-card.component.scss'],
})
export class PendingApprovedStatusCardComponent implements OnInit, OnChanges, OnDestroy {
	@Input() pendingRequest: WithdrawalRequestDto;
	@Input() currency: string;
	@Input() canCancelApplication: boolean;
	@Input() usesSignicatSigning: boolean;
	@Input() isCardBasedLending: boolean;
	//contracs are created after approval in MyFroda
	@Input() shouldSignContractAfterApproval = false;
	@Input() signButtonType?: string = 'primary';
	@Output() cancelApplication = new EventEmitter<void>();
	@Input() widthClass = 'max-w-5xl';
	externalContractStatusUpdating: boolean;
	viewApplication = false;
	showContractsTimeoutError$: Observable<boolean>;
	openingContract = false;
	contractSigningUrl$: Observable<any>;
	contractSigningUrlReady$: Observable<boolean>;
	isBackOfficeUser: boolean;

	protected readonly destroy$ = new Subject();

	constructor(
		private organizationService: OrganizationService,
		private contractService: ContractsHttpService,
		private authService: AuthService
	) {
		this.viewApplication = window.innerWidth < MEDIUM_BREAKPOINT ? true : false;
	}

	ngOnInit(): void {
		this.defineContractsTimeoutTimer();
		if (this.usesSignicatSigning) {
			this.pollForContractSigningUrl();
		} else {
			this.externalContractStatusUpdating = false;
		}
		this.isBackOfficeUser = this.authService.isBackOfficeUser();
	}

	ngOnChanges(changes) {
		if (changes.pendingRequest && changes.pendingRequest.currentValue) {
			const pendingRequest = changes.pendingRequest.currentValue;
			if (allSignatoriesSigned(pendingRequest)) {
				this.externalContractStatusUpdating = false;
			}
		}
	}

	pollForContractSigningUrl() {
		const organizationId = this.organizationService.getOrganizationId();
		this.contractSigningUrl$ = timer(0, CONTRACT_SIGNING_URL_POLLING_INTERVAL).pipe(
			exhaustMap(_ =>
				this.contractService.getContractSigningUrl(organizationId, this.pendingRequest.id).pipe(
					catchError(error => {
						if (this.contractIsGenerating(error)) {
							//contract url is not ready yet, keep polling
							return of(null);
						} else if (this.contractAlreadySigned(error)) {
							if (!allSignatoriesSigned(this.pendingRequest)) {
								//contract is already signed, wait for callback to update signed status
								this.externalContractStatusUpdating = true;
							} else {
								this.externalContractStatusUpdating = false;
							}
						} else if (this.contractExpired(error)) {
							this.externalContractStatusUpdating = false;
						}
						return throwError(error);
					})
				)
			),
			takeWhile(response => !response, true),
			tap(_ => (this.externalContractStatusUpdating = false)),
			publishReplay(1),
			refCount(),
			takeUntil(this.destroy$)
		);

		this.contractSigningUrlReady$ = this.contractSigningUrl$.pipe(
			map(response => !!response),
			catchError(_ => of(false))
		);

		this.contractSigningUrl$.subscribe();
	}

	private contractIsGenerating(error: any) {
		return error.status === CONTRACT_NOT_READY_STATUS;
	}

	private contractExpired(error: any) {
		return (
			error.status === CONTRACT_ALREDY_SIGNED_OR_RECALLED_STATUS && error.message.signingStatus === CONTRACT_RECALLED
		);
	}

	private contractAlreadySigned(error: any): boolean {
		return (
			error.status === CONTRACT_ALREDY_SIGNED_OR_RECALLED_STATUS && error.message.signingStatus === CONTRACT_SIGNED
		);
	}

	defineContractsTimeoutTimer() {
		//show timeout error if contracts are not created within timeout period
		this.showContractsTimeoutError$ = of(false).pipe(
			switchMap(_ =>
				timer(CONTRACT_SIGNING_TIMEOUT).pipe(
					map(_ => !this.shouldSignContractAfterApproval && this.awaitingContractsCreated)
				)
			)
		);
	}

	get awaitingContractsCreated() {
		return !contractsCreated(this.pendingRequest);
	}

	get awaitingSignature() {
		return requestApproved(this.pendingRequest) && !allSignatoriesSigned(this.pendingRequest);
	}

	get awaitingApprovalAndSignature() {
		return !requestApproved(this.pendingRequest) && !allSignatoriesSigned(this.pendingRequest);
	}

	get awaitingApproval() {
		return (
			contractsCreated(this.pendingRequest) &&
			allSignatoriesSigned(this.pendingRequest) &&
			!requestApproved(this.pendingRequest)
		);
	}

	get awaitingApprovalBeforeCreatingContracts() {
		return !contractsCreated(this.pendingRequest) && !requestApproved(this.pendingRequest);
	}

	get contractsSigned() {
		return contractsCreated(this.pendingRequest) && allSignatoriesSigned(this.pendingRequest);
	}

	openContractLink() {
		if (this.awaitingContractsCreated || this.isBackOfficeUser) {
			return;
		}

		this.openingContract = true;
		const organizationId = this.organizationService.getOrganizationId();
		this.contractSigningUrl$
			.pipe(
				filter(response => !!response),
				switchMap(response =>
					//if signing link is expired, get a new one
					iif(
						() => dayjs(response.expires_at).isAfter(new Date()),
						of(response),
						defer(() => this.contractService.getContractSigningUrl(organizationId, this.pendingRequest.id))
					)
				)
			)
			.subscribe(
				response => {
					window.location.href = response.signing_url;
				},
				error => {},
				() => {
					this.openingContract = false;
				}
			);
	}

	ngOnDestroy() {
		this.destroy$.next(undefined);
		this.destroy$.complete();
	}
}
