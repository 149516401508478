<div class="w-full max-w-lg mb-4">
	<div class="relative">
		<div class="pb-1">
			<label class="text-base leading-6 text-black pointer-events-none" for="{{label}}">{{ label }}</label>
		</div>
		<div class="relative h-full">
			<textarea
				class="block w-full p-4 text-base leading-normal text-black transition duration-300 border rounded appearance-none focus:ring-0 cursor-text"
				[ngClass]="{
                'border-medium-grey focus:border-black hover:border-black' : !hasErrors() && !readonly && !disabled,
                'border-system-red focus:border-system-red' : hasErrors() && !readonly && !disabled }"
				[id]="name"
				[name]="name"
				[placeholder]="placeholder"
				[required]="required"
				[disabled]="disabled"
				[readonly]="readonly"
				[rows]="rows"
				[ngModel]="value"
				(ngModelChange)="onChange($event)"
				(blur)="onTouched()"
				[ngModelOptions]="{standalone: true}"
				[pattern]="pattern"></textarea>
			<div class="absolute bottom-4 right-4">
				<app-svg-icon *ngIf="hasErrors()" class="text-system-red" [iconName]="'alert.svg'" [iconId]="'alert'" [width]="'20'" [height]="'20'"></app-svg-icon>
			</div>
		</div>
	</div>
	<div *ngIf="hasErrors()">
		<div *ngFor="let error of control.errors | keyvalue">
			<div class="flex justify-end px-2 pt-1 text-sm text-system-red">
				{{
				error.key === 'distinct' ? ('control.error.' + error.key + '.' + name | translate) :
				error.key === 'pattern' ? ('control.error.pattern' | translate : { name: label}) :
				('control.error.' + error.key | translate) }}
			</div>
		</div>
	</div>
	<div *ngIf="optional" class="flex justify-end px-2 pt-1 text-sm text-dark-grey">
		{{ 'input-component.optional-text' | translate }}
	</div>
</div>
