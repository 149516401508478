import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { smoothOpenClose } from 'app/animations/smooth.animations';
import { FacadeService } from 'app/services';
import { ToastService } from 'app/services/toast.service';
import { ToastDirective } from 'app/shared/directives/toast.directive';
import { swedishMobilePhoneValidator } from 'app/shared/validators';
import { TailwindThemeService } from 'app/tailwind-theme-config/tailwind-theme.service';

const wlStyle = {
	itemBgColor: 'bg-highlight-light',
	itemSelectedBgColor: 'bg-highlighted',
	itemBorderColor: 'border-box-border',
	itemSelectedBorderColor: 'border-highlight-border',
	itemTextColor: 'text-almost-black',
	itemSelectedTextColor: 'text-white',
	itemBorderRadius: 'rounded-box',
	border: 'border-2',
};

const frodaStyle = {
	itemBgColor: 'bg-white',
	itemSelectedBgColor: 'bg-secondary-900',
	itemBorderColor: 'border-secondary-900',
	itemSelectedBorderColor: 'border-secondary-900',
	itemTextColor: 'text-secondary-900',
	itemSelectedTextColor: 'text-white',
	itemBorderRadius: 'rounded-xl',
	border: 'border-2',
};

@Component({
	selector: 'app-tailwind-library',
	templateUrl: './tailwind-library.component.html',
	styleUrls: ['./tailwind-library.component.scss'],
	animations: [smoothOpenClose],
})
export class TailwindLibraryComponent implements OnInit {
	@ViewChild(ToastDirective, { static: true }) toastContainer: ToastDirective;
	myForm: FormGroup;
	options = [
		{ value: 1, label: 'Option 1' },
		{ value: 2, label: 'Option 2' },
	];
	themeName = 'froda';
	amount = { amount: 40000, currency: 'SEK' };
	purposeStyle = frodaStyle;
	purposeList = [
		{
			code: 'seasonal-investment',
			questions: [],
		},
		{
			code: 'inventory',
			questions: [],
		},
		{
			code: 'machines',
			questions: [
				{
					code: 'machines-what',
					type: 'string',
					required: false,
				},
			],
		},
		{
			code: 'refurnishing',
			questions: [
				{
					code: 'refurnishing-what',
					type: 'string',
					required: false,
				},
				{
					code: 'refurnishing-how-affect',
					type: 'string',
					required: false,
				},
			],
		},
		{
			code: 'credit-settlement',
			questions: [
				{
					code: 'credit-settlement-what',
					type: 'string',
					required: false,
				},
				{
					code: 'credit-settlement-how-much',
					type: 'money',
					required: false,
				},
			],
		},
		{
			code: 'general',
			questions: [],
		},
		{
			code: 'unexpected-expenses',
			questions: [
				{
					code: 'unexpected-expenses-what',
					type: 'string',
					required: false,
				},
			],
		},
		{
			code: 'other',
			questions: [
				{
					code: 'other-what',
					type: 'string',
					required: true,
				},
			],
		},
	];

	logoUrl = 'assets/froda/logos/froda-dark.svg';
	reports = [
		{
			id: '2e7463f7-3426-43b2-b1eb-f25a151c8ccb',
			date: '2024-07-01T03:00:52.518+00:00',
			name: '2024-06-01 2024-06-30',
			type: 'monthly-report',
			properties: {
				ReportStartDate: '06/01/2024',
				ReportEndDate: '06/30/2024',
			},
		},
		{
			id: 'a2a6dc1f-6bec-46fd-83af-503591408090',
			date: '2024-06-01T03:00:41.081+00:00',
			name: '2024-05-01 2024-05-31',
			type: 'monthly-report',
			properties: {
				ReportStartDate: '05/01/2024',
				ReportEndDate: '05/31/2024',
			},
		},
	];

	constructor(
		private formBuilder: FormBuilder,
		private facadeService: FacadeService,
		private toastService: ToastService,
		private tailwindThemeService: TailwindThemeService
	) {}

	ngOnInit() {
		this.toastService.setRootViewContainerRef(this.toastContainer);
		this.facadeService.setCurrentFacade('default');

		this.myForm = this.formBuilder.group({
			myInput: [''],
			myInputSuffix: [''],
			myInputPrefix: [''],
			myInputIcon: [''],
			myInputPhone: ['', { validators: swedishMobilePhoneValidator, updateOn: 'blur' }],
			myInputPlaceholder: [''],
			myValidInput: [''],
			myInputError: ['', { validators: [Validators.required] }],
			myInputErrorSufix: ['', { validators: [Validators.required] }],
			myInputValidIndicator: [''],
			myInputValidIndicatorCustomMessage: [''],
			myInputValidIndicatorSuffix: [''],
			myInputHelperText: [''],
			textarea: [''],
			myTextAreaError: ['', { validators: [Validators.required] }],
			mySelect: [''],
			mySelectSelection: [this.options[1]],
			mySelectError: ['', { validators: [Validators.required] }],
			mySlider: [50],
			myCheckbox: [false],
			myCheckboxError: [false, { validators: [Validators.requiredTrue] }],
			myRadioButton: ['1'],
			myHorizOpt: [true],
		});
		this.myForm.markAllAsTouched();
	}

	showToastSuccess() {
		this.toastService.success('Success message');
	}

	showToastError() {
		this.toastService.error('Error message');
	}

	buttonClicked(type: string) {
		console.log('Button clicked', type);
	}

	switchTheme(name) {
		this.themeName = name;
		const theme = this.tailwindThemeService.getThemeByName(name);
		this.tailwindThemeService.updateThemeVariables(theme);
		this.purposeStyle = name === 'froda' ? frodaStyle : wlStyle;
		this.logoUrl = name === 'froda' ? 'assets/froda/logos/froda-dark.svg' : '/assets/' + name + '/icons/logo.svg';
	}

	get isFrodaTheme() {
		return this.themeName === 'froda';
	}
}
