<div class="flex flex-col lg:flex-row" [ngClass]="'theme-' + themeName">
	<div class="flex-1 m-8">
		<div class="flex flex-wrap gap-2 mb-4">
			<app-button [type]="'small'" (action)="switchTheme('froda')">Froda theme</app-button>
			<app-button [type]="'small'" (action)="switchTheme('swedbank-pay')">Swedbank theme</app-button>
			<app-button [type]="'small'" (action)="switchTheme('lunar')">Lunar theme</app-button>
			<app-button [type]="'small'" (action)="switchTheme('yabie')">Yabie theme</app-button>
			<app-button [type]="'small'" (action)="switchTheme('kontist')">Kontist theme</app-button>
			<app-button [type]="'small'" (action)="switchTheme('visma')">Visma theme</app-button>
			<app-button [type]="'small'" (action)="switchTheme('billy')">Billy theme</app-button>
		</div>

		<div class="flex flex-col gap-4 mb-4">
			<h1>New buttons (MF)</h1>
			<div class="flex gap-2">
				<app-button class="w-full max-w-xs" [type]="'primary'" (action)="buttonClicked('primary')">Primary button</app-button>
			</div>
			<div class="flex gap-2">
				<app-button class="w-full max-w-xs" [type]="'secondary'" (action)="buttonClicked('secondary')">Secondary button</app-button>
			</div>

			<div class="flex gap-2">
				<app-button class="w-80" [type]="'primary'" [iconId]="'bankid'" [contentPosition]="'center-right'">Bank ID</app-button>

				<app-button class="w-72" [type]="'primary'">
					<span class="uppercase">UPPERCASE</span>
				</app-button>
			</div>

			<div class="flex gap-2">
				<app-button [type]="'small'" [iconId]="'chevron-down'" (action)="buttonClicked('small')">Small button</app-button>
			</div>

			<div class="flex gap-2">
				<app-button [type]="'outline'" [outlineVersion]="'light'" (action)="buttonClicked('outline')">Outline light</app-button>
			</div>

			<div class="flex gap-2 p-4 bg-dark w-fit">
				<app-button [type]="'outline'" (action)="buttonClicked('outline')">Outline dark</app-button>
			</div>

			<div class="flex gap-2">
				<app-button [type]="'primary'" [loading]="true" (action)="buttonClicked('loading')">Loading button</app-button>
				<app-button [type]="'secondary'" [disabled]="true" (action)="buttonClicked('disabled')">Disabled button</app-button>
			</div>
		</div>

		<h1 class="my-4">Form inputs</h1>
		<div class="mb-10 ">
			<form class="w-full" [formGroup]="myForm">
				<h2 class="mb-4">Input</h2>
				<div class="mb-6">
					<app-input label="Input" name="input" formControlName="myInput"></app-input>
					<app-input label="Input with suffix" name="input" formControlName="myInputSuffix" suffix="suffix"></app-input>
					<app-input label="Input with prefix" name="input" formControlName="myInputPrefix" prefix="prefix"></app-input>
					<app-input label="Input with icon" name="input" formControlName="myInputIcon" [prefixIcon]="'search.svg'" [prefixIconId]="'search'"></app-input>
					<app-input label="Input with placeholder" name="input" formControlName="myInputPlaceholder" placeholder="placeholder"></app-input>
					<app-input label="Input with error and phone mask" name="input" type="tel" formControlName="myInputPhone"></app-input>
					<app-input label="Input with error" name="input" formControlName="myInputError"></app-input>
					<app-input label="Input with error and suffix" name="input" formControlName="myInputErrorSufix" suffix="suffix"></app-input>
					<app-input label="Input with hasValidIndicator" name="input" formControlName="myInputValidIndicator" hasValidIndicator="true"></app-input>
					<app-input
						label="Input with hasValidIndicator and custom validation message"
						name="input"
						formControlName="myInputValidIndicatorCustomMessage"
						hasValidIndicator="true"
						validationMessage="Looks good"></app-input>
					<app-input
						label="Input with hasValidIndicator and suffix"
						name="input"
						formControlName="myInputValidIndicatorSuffix"
						hasValidIndicator="true"
						suffix="suffix"></app-input>
					<app-input label="Input with helperText" name="input" formControlName="myInputHelperText" helperText="Helper text"></app-input>
					<app-textarea label="Textarea" class="text-left" [placeholder]="'Textarea'" [rows]="4" formControlName="textarea"></app-textarea>
					<app-textarea label="Optional Textarea" class="text-left" [placeholder]="'Textarea'" [rows]="4" formControlName="textarea" [optional]="true"></app-textarea>
					<app-textarea label="Textarea with error" label="Textarea" class="text-left" [placeholder]="'Textarea'" [rows]="4" formControlName="myTextAreaError"></app-textarea>
				</div>
				<h2 class="mb-4">Select</h2>
				<div class="mb-6">
					<app-select label="Select with placeholder" name="banks" [options]="options" formControlName="mySelect" [placeholder]="'Placeholder'"></app-select>
					<app-select label="Selection" name="banks" [options]="options" formControlName="mySelectSelection"></app-select>
					<app-select label="Select with error" name="banks" [options]="options" formControlName="mySelectError" [placeholder]="'Placeholder'"></app-select>
					<app-select label="Optional select" name="banks" [options]="options" formControlName="mySelect" [placeholder]="'Placeholder'" [optional]="true"></app-select>
				</div>
				<h2 class="mb-4">Slider</h2>
				<div class="mb-10">
					<app-slider min="0" max="100" formControlName="mySlider"></app-slider>
				</div>
				<h2 class="mb-4">Checkbox</h2>
				<div class="mb-10">
					<app-checkbox formControlName="myCheckbox">
						<span text>I approve of this checkbox</span>
						<span error>You need to approve this checkbox</span>
					</app-checkbox>
					<app-checkbox formControlName="myCheckboxError">
						<span text>I approve of this checkbox</span>
						<span error>You need to approve this checkbox</span>
					</app-checkbox>
				</div>
				<h2 class="mb-4">Radio buttons</h2>
				<div class="flex flex-col mb-10">
					<app-radio-button class="mb-2" value="1" formControlName="myRadioButton">Radio button</app-radio-button>
					<app-radio-button class="mb-2" value="2" formControlName="myRadioButton">Radio button</app-radio-button>
					<app-radio-button class="mb-2" value="3" formControlName="myRadioButton">Radio button</app-radio-button>
				</div>
				<h2 class="mb-4">New horizontal options</h2>
				<div class="flex items-center gap-4 mb-10">
					<app-radio-select-item [value]="false" formControlName="myHorizOpt" [title]="'No'" [borderRadius]="'rounded'"></app-radio-select-item>
					<app-radio-select-item [value]="true" formControlName="myHorizOpt" [title]="'Yes'" [borderRadius]="'rounded'"></app-radio-select-item>
				</div>
				<ng-container *ngIf="themeName !== 'froda'">
					<h1 class="mb-4">Price box</h1>
					<div class="mb-10">
						<div class="flex flex-col gap-common p-common md:p-0 md:py-common">
							<div class="flex-1 border-2 p-common rounded-box bg-box-background border-box-border">
								<div class="text-sm font-bold truncate text-box-info">Available loan amount</div>
								<div class="flex items-center justify-between w-full whitespace-nowrap">
									<div>
										<div>
											<h2 class="text-5xl text-box-info">
												{{ amount  | money:0 }}
											</h2>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="flex-1 border-2 p-common rounded-box bg-box-background-light border-box-border-light">
							<div class="flex items-center justify-between w-full">
								<!-- if it's pending WR - show new_principal_due-->
								<div>
									<div class="mr-2">
										<div class="text-sm font-bold text-box-info-light">Existing loan</div>
										<div>
											<h2 class="text-5xl text-box-info-light">
												{{ amount  | money:0 }}
											</h2>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</ng-container>

				<h1 class="mb-4">Purpose list</h1>
				<div class="flex items-start mb-10">
					<app-loan-purpose-list
						class="h-full my-common"
						[imageSourceUrl]="themeName === 'froda' ? '/assets/shared/icons/purpose/' : '/assets/' + themeName + '/icons/purpose/'"
						[purpose]="purposeList"
						[showSubmitButton]="false"
						[itemStyle]="purposeStyle"></app-loan-purpose-list>
				</div>

				<h1 class="mb-4">Home reports</h1>
				<div class="mb-10">
					<home-reports [fixedHeight]="false" [reports]="reports"></home-reports>
				</div>
				<h1 class="mb-4">Home faqs</h1>
				<div class="mb-10">
					<div class="w-3/4">
						<app-home-faqs [keys]="['home.faq.questions.reason-repayment-every-day', 'home.faq.questions.repay-in-advance']"></app-home-faqs>
					</div>
				</div>
				<h1 class="mb-4">Status step (bgColor and textColor can be changed)</h1>
				<div class="flex flex-col items-start mb-10">
					<app-status-step class="order-1" [title]="'complete.agreement.title'" step="1" currentStep="2">
						<ng-container>
							<p class="mb-2" translate="complete.agreement.pre"></p>
						</ng-container>
					</app-status-step>
					<app-status-step class="order-3" [title]="'complete.paid-out.title'" isLastStep="true" step="2">
						<ng-container>
							<p class="mb-2" translate="complete.paid-out.pre"></p>
						</ng-container>
					</app-status-step>
				</div>
			</form>
		</div>
	</div>
	<div class="flex-1 m-8">
		<h1 class="mb-4">Colors</h1>
		<div class="mb-10 text-white">
			<h2 class="mb-2">Primary</h2>
			<div class="flex flex-wrap gap-4 mb-4">
				<div class="w-10 h-10 bg-primary-100"></div>
				<div class="w-10 h-10 bg-primary-200"></div>
				<div class="w-10 h-10 bg-primary-400"></div>
				<div class="w-10 h-10 bg-primary-500"></div>
				<div class="w-10 h-10 bg-primary-700"></div>
			</div>
			<h2 class="mb-2">Secondary</h2>
			<div class="flex flex-wrap gap-4 mb-4">
				<div class="w-10 h-10 bg-secondary-100"></div>
				<div class="w-10 h-10 bg-secondary-200"></div>
				<div class="w-10 h-10 bg-secondary-400"></div>
				<div class="w-10 h-10 bg-secondary-500"></div>
				<div class="w-10 h-10 bg-secondary-900"></div>
			</div>
			<h2 class="mb-2">Grays</h2>
			<div class="flex flex-wrap gap-4 mb-4">
				<div class="w-10 h-10 bg-almost-white"></div>
				<div class="w-10 h-10 bg-light-grey"></div>
				<div class="w-10 h-10 bg-medium-grey"></div>
				<div class="w-10 h-10 bg-dark-grey"></div>
				<div class="w-10 h-10 bg-almost-black"></div>
			</div>
			<h2 class="mb-2">System red</h2>
			<div class="flex flex-wrap gap-4 mb-4">
				<div class="w-10 h-10 bg-system-red"></div>
			</div>
			<h2 class="mb-2">System yellow</h2>
			<div class="flex flex-wrap gap-4 mb-4">
				<div class="w-10 h-10 bg-system-yellow"><div class="flex items-center justify-center w-full h-full ">*</div></div>
			</div>
			<h2 class="mb-2">System green</h2>
			<div class="flex flex-wrap gap-4 mb-4">
				<div class="w-10 h-10 bg-system-green"><div class="flex items-center justify-center w-full h-full ">*</div></div>
			</div>
			<h2 class="mb-2">System blue</h2>
			<div class="flex flex-wrap gap-4 mb-4">
				<div class="w-10 h-10 bg-system-blue"><div class="flex items-center justify-center w-full h-full ">*</div></div>
			</div>
			<h2 class="mb-2">Highlighted color</h2>
			<div class="flex flex-wrap gap-4 mb-4">
				<div class="w-10 h-10 bg-highlighted"><div class="flex items-center justify-center w-full h-full "></div></div>
			</div>
			<h2 class="mb-2">Title color</h2>
			<div class="flex flex-wrap gap-4 mb-4">
				<div class="w-10 h-10 bg-title"><div class="flex items-center justify-center w-full h-full "></div></div>
			</div>
			<h2 class="mb-2">Paragraph color</h2>
			<div class="flex flex-wrap gap-4 mb-4">
				<div class="w-10 h-10 bg-paragraph"><div class="flex items-center justify-center w-full h-full "></div></div>
			</div>
			<div class="text-sm text-black text-bold">* - used in few places, candidates for removal</div>
		</div>
		<h1 class="mb-4">Typography</h1>
		<article class="flex flex-col gap-4 mb-10">
			<h1 class="text-5xl">Hello, this is the biggest text</h1>
			<h1>Hello, this is h1</h1>
			<h2>Hello, this is h2</h2>
			<h3>Hello, this is h3</h3>

			<p class="text-lg">
				There was not a VERY good opportunity for showing off her knowledge, as there was no `One, two, three, and away,' but they began running when they liked, and left off when
				they liked, and left off when they liked, and left off when they.
			</p>
			<p>
				Light from a service hatch at the rear of the Villa bespeak a turning in, a denial of the bright void beyond the hull. The alarm still oscillated, louder here, the rear
				wall dulling the roar of the deck sting his palm as he made his way down Shiga from the sushi stall he cradled it in his jacket pocket. Molly hadn’t seen the dead girl’s
				face swirl like smoke, to take on the wall between the bookcases, its distorted face sagging to the Tank War, mouth touched with hot gold as a gliding cursor struck sparks
				from the wall of a heroin factory.
			</p>
			<p class="text-sm">
				They floated in the Japanese night like live wire voodoo and he’d cry for it, cry in his capsule in some coffin hotel, his hands clawed into the shadow of the console.
				Still it was a square of faint light. He woke and found her stretched beside him in the tunnel’s ceiling. The semiotics of the room where Case waited. Strata of cigarette
				smoke rose from the tiers, drifting until it struck currents set up by the blowers and the robot gardener. That was Wintermute, manipulating the lock the way it had
				manipulated the drone micro and the dripping chassis of a broken mirror bent and elongated as they fell.
			</p>
		</article>
		<h1 class="mb-4">Notifications</h1>
		<div class="mb-10 ">
			<app-notification type="alert" closable="true">Uploading data manually will add additional processing time of up to two days for your application.</app-notification>
			<app-notification type="error" closable="true">An error occurred while trying to contact your bank.</app-notification>
			<app-notification type="info" closable="true">
				Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid pariatur, ipsum similique veniam. Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid
				pariatur, ipsum similique veniam. Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid pariatur, ipsum similique veniam.
			</app-notification>
			<app-notification type="success">Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid pariatur, ipsum similique veniam.</app-notification>
		</div>
		<h1 class="mb-4">Badges</h1>
		<div class="flex gap-2 mb-10">
			<app-badge type="primary">Primary</app-badge>
			<app-badge type="pending">Gray</app-badge>
			<app-badge type="error">Error</app-badge>
			<app-badge type="alert">Alert</app-badge>
		</div>
		<h1 class="mb-4">Toast message</h1>
		<div class="flex gap-2 mb-10">
			<app-button (action)="showToastSuccess()">Success</app-button>
			<app-button (action)="showToastError()">Error</app-button>
		</div>
		<h1 class="mb-4">Progress bars</h1>
		<div class="">
			<h2 class="mb-4">Progress bar</h2>
			<div class="mb-10 ">
				<div class="mb-2">
					<app-progress-bar filled="0"></app-progress-bar>
				</div>
				<div class="mb-2">
					<app-progress-bar filled="50"></app-progress-bar>
				</div>
				<div class="mb-2">
					<app-progress-bar filled="100"></app-progress-bar>
				</div>
			</div>
			<h2 class="mb-4">Loading indicator</h2>
			<div class="mb-10 ">
				<app-loader></app-loader>
			</div>

			<h2 class="mb-4">Loading text</h2>
			<div class="">
				<h2 *loadingDots="false">Text</h2>
			</div>
		</div>
		<h1 class="mb-4">Icons</h1>
		<div class="mb-10">
			<div class="flex flex-col gap-4 mb-4">
				<app-button [iconId]="'plus-icon'" type="small">Show all options</app-button>
				<div class="flex gap-4 mb-4">
					<app-svg-icon class="text-secondary-400" [iconName]="'radio.svg'" [iconPath]="'assets/onboarding/icons/marketing-source/'" [iconId]="'radio'"></app-svg-icon>
					<app-svg-icon class="text-secondary-900" [iconName]="'tv.svg'" [iconPath]="'assets/onboarding/icons/marketing-source/'" [iconId]="'tv'"></app-svg-icon>
					<app-svg-icon class="text-red-700" [iconName]="'google.svg'" [iconPath]="'assets/onboarding/icons/marketing-source/'" [iconId]="'google'"></app-svg-icon>
					<app-svg-icon class="text-secondary-900" [iconName]="'general.svg'" [iconPath]="'assets/shared/icons/purpose/'" [iconId]="'general'"></app-svg-icon>
					<app-svg-icon
						class="text-secondary-900"
						[iconName]="'seasonal-investment.svg'"
						[iconPath]="'assets/shared/icons/purpose/'"
						[iconId]="'seasonal-investment'"></app-svg-icon>
					<app-svg-icon [iconName]="'delete-circle.svg'" [iconId]="'delete-circle'"></app-svg-icon>

					<div class="text-white bg-secondary-900">
						<app-svg-icon [iconName]="'plus-icon.svg'" [iconId]="'plus-icon'"></app-svg-icon>
					</div>
				</div>
			</div>
		</div>
		<h1 class="mb-4">Logo</h1>
		<img class="w-auto h-20 max-w-xs" src="{{logoUrl}}" />
	</div>

	<ng-template toast></ng-template>
</div>
