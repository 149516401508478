import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
	selector: 'app-textarea',
	templateUrl: './textarea.component.html',
	styleUrls: ['./textarea.component.scss'],
})
export class TextareaComponent implements OnInit, ControlValueAccessor {
	@Input() label: string;
	@Input() name: string;
	@Input() value;
	@Input() placeholder = '';
	@Input() required = false;
	@Input() disabled = false;
	@Input() readonly = false;
	@Input() pattern: string;
	@Input() rows = 5;
	@Input() optional = false;

	onTouched = () => {};
	onChanged = (_: any) => {};

	constructor(@Self() @Optional() public control: NgControl) {
		this.control.valueAccessor = this;
	}

	ngOnInit(): void {}

	writeValue(value: any): void {
		this.value = value;
	}

	registerOnChange(fn: any): void {
		this.onChanged = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	onChange(value) {
		this.value = value;
		this.onChanged(this.value);
	}

	hasErrors() {
		return (this.control.dirty || this.control.touched) && this.control.errors;
	}
}
