<div class="w-full" [class.pb-7]="!hasErrors() && !helperText && (!hasValidIndicator || !validationMessage)">
	<div class="mb-1">
		<label class="text-base text-black pointer-events-none" for="{{label}}">{{ label }}</label>
	</div>
	<div
		class="flex items-center rounded border h-12"
		[ngClass]="{
			'border-medium-grey': !(hasErrors() && !readonly),
			'hover:border-black': !(hasErrors() && !readonly),
			'focus-within:border-black' : !hasErrors() && !readonly,
			'border-system-red' : hasErrors() && !readonly,
			'focus-within:border-system-red' : hasErrors() && !readonly}">
		<div *ngIf="prefix || prefixIcon" class="h-full text-black/60 pl-4 flex items-center">
			<ng-container *ngIf="prefix">
				{{ prefix }}
			</ng-container>
			<app-svg-icon *ngIf="prefixIcon" class="text-black/60" width="20" height="20" [iconName]="prefixIcon" [iconId]="prefixIconId"></app-svg-icon>
		</div>
		<input
			class="placeholder-medium-grey block w-full pl-4 text-base text-black  outline-none appearance-none cursor-text focus:ring-0 border-none "
			[id]="name"
			[name]="name"
			[type]="type !== 'number' ? type : 'text'"
			[autocomplete]="autocomplete"
			[placeholder]="placeholder || 'input.placeholder' | translate"
			[required]="required"
			[readonly]="readonly"
			[ngModel]="value"
			(ngModelChange)="onChange($event)"
			(blur)="onBlur()"
			(focus)="onFocus()"
			[ngModelOptions]="{standalone : true}"
			[pattern]="type === 'number' && !pattern ? '[0-9]*' : pattern"
			(keypress)="preventNonNumeric($event)"
			data-testid="input"/>
		<div *ngIf="suffix || hasErrors() || hasValidIndicator" class="flex items-center pr-4 text-black/60">
			<app-svg-icon *ngIf="hasErrors()" class="text-system-red" width="20" height="20" [iconName]="'alert.svg'" [iconId]="'alert'"></app-svg-icon>
			<app-svg-icon
				*ngIf="hasValidIndicator && !hasErrors()"
				class="text-system-green"
				width="20"
				height="20"
				[iconName]="'check-circle.svg'"
				[iconId]="'check-circle'"></app-svg-icon>
			<span *ngIf="suffix" class="ml-4">
				{{ suffix }}
			</span>
		</div>
	</div>

	<span *ngIf="hasValidIndicator && validationMessage && !hasErrors()" class="flex justify-end pt-1 pr-4 text-sm text-system-green">{{ validationMessage | translate }}</span>

	<span *ngIf="hasErrors()" class="flex justify-end pt-1 pr-4 text-sm text-system-red">
		{{
					errorMessage === 'distinct' ? ('control.error.' + errorMessage + '.' + name | translate) :
					errorMessage === 'pattern' ? ('control.error.pattern' | translate : { name: label}) :
				('control.error.' + errorMessage | translate) }}
	</span>

	<span *ngIf="helperText && !hasErrors()" class="flex justify-end pt-1 pr-4 text-sm text-dark-grey" [innerHtml]="helperText" (click)="helperTextClick.emit()"></span>
</div>
